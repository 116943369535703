"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ClsLogger_1 = require("./ClsLogger");
var ClsErrosAplicacao = /** @class */ (function () {
    function ClsErrosAplicacao() {
    }
    ClsErrosAplicacao.prototype.erroBanco = function (sistema, origem, observacao, objetoErr) {
        if (observacao === void 0) { observacao = ""; }
        ClsLogger_1.default.logar('ATENCAO - ERRO NO BANCO A SER TRATADO');
        ClsLogger_1.default.objeto({
            sistema: sistema,
            origem: origem,
            observacao: observacao,
            err: objetoErr
        });
    };
    return ClsErrosAplicacao;
}());
exports.default = new ClsErrosAplicacao();
