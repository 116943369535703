"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DIASDASEMANA = exports.DTFORMAT = void 0;
exports.DTFORMAT = Object.freeze({
    USUARIO: 'DD/MM/YYYY',
    USUARIO_DATETIME: 'DD/MM/YYYY HH:mm:ss',
    BANCO: 'YYYY-MM-DD',
    BANCO_DATETIME: 'YYYY-MM-DD HH:mm:ss',
    UTC: 'YYYY-MM-DDTHH:mm:ssZ'
});
exports.DIASDASEMANA = Object.freeze([
    { idDia: 0, descricao: 'Domingo' },
    { idDia: 1, descricao: 'Segunda' },
    { idDia: 2, descricao: 'Terca' },
    { idDia: 3, descricao: 'Quarta' },
    { idDia: 4, descricao: 'Quinta' },
    { idDia: 5, descricao: 'Sexta' },
    { idDia: 6, descricao: 'Sabado' }
]);
