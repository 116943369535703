"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DataTypes_1 = require("./DataTypes");
var moment = require("moment");
var _ = require("lodash");
var ClsUtils = /** @class */ (function () {
    function ClsUtils() {
    }
    /**
   * Retorna um Key Value Object de um Array de Objectos Key Value Enviado
   * @param array Array de objetos
   * @returns Object
   */
    ClsUtils.prototype.arrayToKeyValueObject = function (array) {
        var retorno = {};
        array.forEach(function (valor) {
            Object.entries(valor).forEach(function (element) {
                retorno[element[0]] = element[1];
            });
        });
        return retorno;
    };
    /**
     * Comparar conteudo de dois objetos e retorna um array com as propriedades diferentes
     * @param obj1 Objeto 1 a ser comparado
     * @param obj2 Objeto 2 a ser comparado
     * @returns Array com as propriedades que são diferentes
     */
    ClsUtils.prototype.compararPropriedadesObjetos = function (obj1, obj2) {
        var retorno = [];
        Object.keys(obj1).forEach(function (chave) {
            if (obj1[chave] != obj2[chave]) {
                retorno.push(chave);
            }
        });
        return retorno;
    };
    /**
     * Realiza o allTrim inclusive para os códigos html 8203 (space with zero)
     * @param oque Objeto para trim
     */
    ClsUtils.prototype.trimWith8203 = function (oque) {
        return oque.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, '').trim();
    };
    /**
     * Converte strings numéricas em formato compatível com banco de dados - separador decimal por ponto e sem separador de milhar
     * @param numeroString Número no formato de String com separador decimal como virgula e milhar como ponto
     */
    ClsUtils.prototype.numeroVirgulaParaPonto = function (numeroString) {
        return parseFloat(numeroString.replace('.', '').replace(',', '.').replace(' ', ''));
    };
    /**
   * Gerar Chave para identificação via token
   * Gera de forma randomica
   * @returns
   */
    ClsUtils.prototype.gerarToken = function () {
        var random = function () { return (0x10000 | (Math.random() * 0x10000)).toString(16).substr(1); };
        return random()
            .concat(random())
            .concat(random())
            .concat(random())
            .concat(random())
            .concat(random())
            .concat(moment.now().toString())
            .concat(random())
            .concat(random());
    };
    ClsUtils.prototype.dataValida = function (data, formato) {
        if (formato === void 0) { formato = DataTypes_1.DTFORMAT.USUARIO; }
        return !(data === null) && moment(data, formato).isValid();
    };
    ClsUtils.prototype.jsonToSearchParameters = function (obj) {
        var searchParams = new URLSearchParams();
        Object.keys(obj).forEach(function (propriedade) {
            searchParams.append(propriedade, obj[propriedade].toString());
        });
        return searchParams.toString();
    };
    ClsUtils.prototype.ConverterEmGql = function (obj) {
        var _this = this;
        // Make sure we don't alter integers.
        if (typeof obj === 'number' || obj == null) {
            return obj;
        }
        if (Array.isArray(obj)) {
            var props = obj.map(function (value) { return "" + _this.ConverterEmGql(value); }).join(',');
            return "[" + props + "]";
        }
        if (typeof obj === 'object') {
            var props = Object.keys(obj)
                .filter(function (key) { return key != "__typename"; })
                .map(function (key) { return key + ":" + _this.ConverterEmGql(obj[key]); })
                .join(',');
            return "{" + props + "}";
        }
        return JSON.stringify(obj);
    };
    ClsUtils.prototype.removeNullFromObject = function (obj) {
        obj = _.pickBy(obj, function (v) { return v !== undefined; });
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
        return obj;
    };
    /* ================= */
    ClsUtils.prototype.getSomenteNumeros = function (oque) {
        if (oque === void 0) { oque = ''; }
        var retorno = oque.match(/\d/g);
        return retorno ? retorno.join("") : '';
    };
    ClsUtils.prototype.emailValido = function (email) {
        if (email === void 0) { email = ''; }
        return /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/.test(email) && email.length >= 10;
    };
    ClsUtils.prototype.foneValido = function (fone) {
        if (fone === void 0) { fone = ''; }
        var numeros = this.getSomenteNumeros(fone);
        return (numeros.length == 10 || numeros.length == 11) && numeros.substr(0, 1) != "0";
    };
    ClsUtils.prototype.procurarPropriedadeObjeto = function (array, chaveProcurada, valorProcurado) {
        var _this = this;
        var retorno = {};
        array.forEach(function (valor) {
            Object.entries(valor).forEach(function (element) {
                // console.log( element[0] == chaveProcurada )
                // console.log( 'element 1', element[1], typeof element[1] )
                if (typeof element[1] == 'object')
                    retorno = _this.procurarPropriedadeObjeto(element[1], chaveProcurada, valorProcurado);
                if (element[0] == chaveProcurada && element[1] == valorProcurado) {
                    // console.log( 'valor Encontrado:', valor )
                    retorno = valor;
                }
            });
        });
        return retorno;
    };
    return ClsUtils;
}());
exports.default = ClsUtils;
