/*
interface SISTEMA_PERMISSOES_INTERFACE {
  [key: string]: {
    MODULO: string
    PERMISSOES: {
      [key: string]: string
      // EXCLUIR: string,

    }
  }
}
*/

export interface SISTEMA_PERMISSOES_INTERFACE {
  GRUPOS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  USUARIOS: {
    MODULO: string
    PERMISSOES: {
      USUARIOS_GLOBAIS: string
      CADASTRO_USUARIO_UNIDADE: string
    }
  }
}

export const MAXIMO_ERRO_LOGIN: number = 3

export const SISTEMA_PERMISSOES: SISTEMA_PERMISSOES_INTERFACE = {

  GRUPOS: {
    MODULO: 'Cadastro de Grupos de Usuários',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Grupos'
    }
  },

  USUARIOS: {
    MODULO: 'Cadastro de Usuário',
    PERMISSOES: {
      USUARIOS_GLOBAIS: 'Manutenção de Usuários Globais',
      CADASTRO_USUARIO_UNIDADE: 'Manutenção de Usuários da Unidade'
    }
  }
}
