import React, { useState } from 'react'
import InputText from '../DevComponents/InputText'

export default function TesteBox () {

  const [dados, setDados] = useState( { telefone: '' } )

  return (
    <>
      <InputText
        dados={dados}
        field="telefone"
        label="Fone"
        setState={setDados}
        mask={"tel"}
      />
      <br />
      <p>{dados.telefone}</p>
      <p>{dados.telefone.length}</p>
    </>
  )
}