import { NavigateFunction } from "react-router-dom"
import { LayoutStateInterface } from "../../GlobalStates/LayoutState"
import { LoginStateInterface } from "../../GlobalStates/LoginState"

export class ClsLogout {

  public efetuarLogout (
    setLoginState: React.Dispatch<React.SetStateAction<LoginStateInterface>>,
    setLayoutState: React.Dispatch<React.SetStateAction<LayoutStateInterface>>,
    navigate: NavigateFunction
  ) {

    localStorage.removeItem( 'token' )

    // Reset LoginState
    setLoginState( {
      logado: false,
      idUsuario: 0,
      permissoes: [],
      token: '',
      nome: ''
    } )

    // Reset LauoutState
    setLayoutState( {
      exibirMenu: false,
      opcoesMenu: []
    } )

    navigate( '/' )
  }
}