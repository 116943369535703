"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ClsUtils_1 = require("./ClsUtils");
var ufs = [
    'AC',
    'AL',
    'AM',
    'AP',
    'BA',
    'CE',
    'ES',
    'GO',
    'MA',
    'MG',
    'MS',
    'MT',
    'PA',
    'PB',
    'PE',
    'PI',
    'PR',
    'RJ',
    'RN',
    'RO',
    'RR',
    'RS',
    'SC',
    'SE',
    'SP',
    'TO'
];
var mesAnoValido = function validarMesAno(mesAno) {
    var retorno = false;
    if (mesAno.length == 7) {
        if (mesAno.substr(0, 2) >= '01' && mesAno.substr(0, 2) <= '12') {
            retorno = true;
        }
    }
    return retorno;
};
var eCNPJ = function validarCNPJ(cnpj) {
    cnpj = limparMascara(cnpj);
    if (cnpj == '')
        return false;
    if (cnpj.length != 14)
        return false;
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;
    // Valida DVs
    var tamanho = cnpj.length - 2;
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(0)))
        return false;
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(1)))
        return false;
    return true;
};
var limparMascara = function (valor) {
    return valor.replace(/[^\d]+/g, '');
};
var scorePassword = function (pass) {
    var score = 0;
    if (!pass)
        return score;
    // award every unique letter until 5 repetitions
    var letters = new Object();
    for (var i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
    }
    // bonus points for mixing it up
    var variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    };
    var variationCount = 0;
    for (var check in variations) {
        variationCount += (variations[check] == true) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    return parseInt(score.toString());
};
var eCPF = function TestaCPF(CPF) {
    var Soma = 0;
    var Resto = 0;
    var strCPF = limparMascara(CPF);
    if (strCPF == '')
        return false;
    if (strCPF.length != 11)
        return false;
    // Elimina CNPJs invalidos conhecidos
    if (strCPF == "00000000000" ||
        strCPF == "11111111111" ||
        strCPF == "22222222222" ||
        strCPF == "33333333333" ||
        strCPF == "44444444444" ||
        strCPF == "55555555555" ||
        strCPF == "66666666666" ||
        strCPF == "77777777777" ||
        strCPF == "88888888888" ||
        strCPF == "99999999999")
        return false;
    Soma = 0;
    for (var i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11))
        Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)))
        return false;
    Soma = 0;
    for (var i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11))
        Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11)))
        return false;
    return true;
};
exports.default = {
    nome: [
        function (v) {
            return v.length <= 40 || 'Nome Extenso! Abrevie. Máximo de 40 caracteres.';
        }
    ],
    email: [
        function (v) {
            return /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/.test(v) || 'E-mail deve ser válido!';
        }
    ],
    login: [
        function (v) {
            return (!!v && /^[0-9@._A-Za-z]{10,}$/.test(v) && v.length >= 10 && v.length <= 30) ||
                'Login de Letras e Numeros a partir de 10 até 30 caracteres';
        }
    ],
    senha: [
        function (v) {
            return (!!v &&
                /^[a-zA-Z0-9#@$!*](_(?!(\.|_))|\.(?!(_|\.))|[a-zA-Z0-9#@$!*])[a-zA-Z0-9#@$!*]{4,23}$/.test(v) && scorePassword(v) >= 50) && v.length >= 6 ||
                'Mínimo 6 caracteres. Números e Letras. Maiúsculas e Minúsculas. Não sequenciais.';
        }
    ],
    naoVazio: [function (v) { return !!v || 'Informação Obrigatória!'; }],
    foneFixoCel: [
        function (v) {
            return !v ||
                v.length === 11 ||
                v.length === 10 ||
                'Telefone deve Fixo ou Celular!';
        }
    ],
    foneCelular: [
        function (v) { return !v || v.length === 11 || 'Fone Celular deve ser completo!'; }
    ],
    cep: [function (v) { return !v || v.length === 8 || 'CEP deve ser completo!'; }],
    endereco: [
        function (v) {
            return v.length <= 50 || 'Endereço Extenso! Abrevie. Máximo de 50 caracteres.';
        }
    ],
    uf: [
        function (v) {
            return (ufs.indexOf(v) >= 0 && v.trim().length === 2) || 'UF Inválido!';
        }
    ],
    complemento: [
        function (v) {
            return v.length <= 30 || 'Complemento Extenso! Abrevie. Máximo de 30 caracteres.';
        }
    ],
    bairro: [
        function (v) {
            return v.length <= 35 || 'Bairro Extenso! Abrevie. Máximo de 35 caracteres.';
        }
    ],
    cidade: [
        function (v) {
            return v.length <= 35 || 'Cidade Extensa! Abrevie. Máximo de 35 caracteres.';
        }
    ],
    getUFs: ufs,
    sgIdUsuario: [
        function (v) {
            return (!!v && /^[0-9@._A-Za-z]$/.test(v) && v.length <= 30) ||
                'Login de Letras e Numeros até 30 caracteres';
        }
    ],
    horaMinutoValida: [
        function (v) {
            return (!!v && /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/.test(v) && v.length == 5 && v < '24:00') ||
                'Hora Inválida';
        }
    ],
    horaMinutoSegundoValida: [
        function (v) {
            return (!!v && /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(v) && v.length == 8 && v < '24:00:00') ||
                'Hora Inválida';
        }
    ],
    mesAno: [function (v) {
            return !v || mesAnoValido(v) || 'Mês / Ano Inválido';
        }],
    cnpj: [function (v) {
            return !v || eCNPJ(v) || 'CNPJ Inválido';
        }],
    cpf: [function (v) {
            return !v || eCPF(v) || 'CPF Inválido';
        }],
    dataValida: [function (v) { return !v || (new ClsUtils_1.default()).dataValida(v) || "Data Inválida"; }]
};
