import React from 'react'

import { createBrowserRouter } from 'react-router-dom'
import ErroAplicacao from '../Layout/ErroAplicacao'
import Layout from '../Layout/Layout'
import ExemploMenu from '../exemplo_apagar/menu'
import TesteBox from '../exemplo_apagar/TesteBox'
import Usuarios from '../View/Crud/Sistema/Usuarios'
import Grupos from '../View/Crud/Sistema/Grupos'
import Logout from '../View/Controller/Logout'
import AlterarSenha from '../View/Controller/AlterarSenha'
import Login from '../View/Controller/Login'
import ImportarDRG from '../View/ImportarDRG'

export const router = createBrowserRouter( [
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErroAplicacao />,
    children: [
      {
        path: "AlterarSenha",
        element: <AlterarSenha />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Grupos",
        element: <Grupos />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Logout",
        element: <Logout />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Usuarios",
        element: <Usuarios />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Login",
        element: <Login />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "ImportarDRG",
        element: <ImportarDRG />,
        errorElement: <ErroAplicacao />
      }
    ]
  },
  {
    path: "/ErroAplicacao",
    element: <ErroAplicacao />
  },
  {
    path: "/ExemploMenu",
    element: <ExemploMenu />
  },
  {
    path: "/TesteBox",
    element: <TesteBox />
  }
] );