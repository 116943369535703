import React, { useContext, useState } from 'react'
import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import InputText from '../DevComponents/InputText';
import { DateTime } from 'luxon';
import ClsValidacao from '../Utils/ClsValidacao';
import BackEndAPI from '../Services/BackEndAPI';
import { ContextoGlobal, ContextoGlobalInterface } from '../GlobalStates/ContextoGlobal';

interface DadosInterface {
  inicio: string,
  termino: string
}

export default function ImportarDRG () {

  const clsApi = new BackEndAPI()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const navigate = useNavigate()

  const btFechar = () => {
    navigate( '/' )
  }

  const [dados, setDados] = useState<DadosInterface>( {
    inicio: DateTime.now().toISODate() as string,
    termino: DateTime.now().toISODate() as string
  } )

  const [erros, setErros] = useState( {} )

  const btDownload = () => {

    if ( validarDados() ) {

      const query: string = `
        downloadDRG(inicio: "${dados.inicio}", termino: "${dados.termino}")
      `

      clsApi.query<string>( query, 'downloadDRG', 'Download DRG', contexto ).then( rsDownload => {

        let link = document.createElement( 'a' )
        link.href = 'data:text/csv;charset=utf-8,%EF%BB%BF'.concat( rsDownload );
        link.download = "DRG".concat( "-" ).concat( dados.inicio ).concat( '-' ).concat( dados.termino ).concat( ".csv" )
        link.click()

      } )

    }

  }

  const validarDados = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eData( 'inicio', dados, erros, retorno )
    retorno = clsValidacao.eData( 'termino', dados, erros, retorno )

    if ( retorno && dados.termino < dados.inicio ) {
      erros.inicio = 'Período Inválido!'
      erros.termino = 'Período Inválido!'
    }

    setErros( erros )

    return retorno
  }

  return (
    <>
      <Container maxWidth="sm" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'center' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Download DRG
                <Typography variant="body2" gutterBottom>
                  Download dos dados previamente enviados ao DRG Brasil
                </Typography>
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>


            <Grid item xs={12} sm={6} >
              <InputText
                erros={erros}
                dados={dados}
                field='inicio'
                label='Início'
                setState={setDados}
                type='date'
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
              <InputText
                erros={erros}
                dados={dados}
                field='termino'
                label='Término'
                setState={setDados}
                type='date'
              />

            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'right', mt: 5 }}>
              <Button variant='contained' onClick={() => btDownload()}>Download</Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  )

}